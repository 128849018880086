import './App.css';
import GameBoard from './GameBoard';

function App() {
  return (
    <div className='container'>
      <div className='title'>
        <h1>tic-tac-toe</h1>
        <span>x o</span>
      </div>
      <GameBoard />
      <div>
        <a
          href='https://www.broccolirecords.com/'
          target='_blank'
          rel='noreferrer'
          className='link'
        >
          <h3>broccoli records 🥦</h3>
        </a>
      </div>
    </div>
  );
}

export default App;
